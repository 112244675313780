import React, { useEffect, useState } from "react";
import { useAuth } from "../Contexts/AuthContext";

export default function Dropdown(props) {
    const [topics, setTopics] = useState([]);
    const { token } = useAuth();

    const getTopics = async () => {
        await fetch(`/backend/topics`, {
            method: "GET",
            headers: {
                Authorization: `Bearer ${token}`,
                "Content-Type": "application/json",
            },
            mode: "cors",
        })
        .then((response) => {
            if (response.status === 200) {
                return response.json();
            } else {
                console.error("Error fetching topics:", response);
                throw new Error("Error fetching topics");
            }
        })
        .then((data) => {
            setTopics(data);
        })
        .catch((error) => console.error("Error fetching topics:", error));
    };

    // use useEffect to fetch names of topics
    // TODO: better error checking and handling?
    useEffect(() => {
        getTopics();
    }, []);

    // whenever the user chooses a topic, call the onSelectionChange function
    const handleChange = (e) => {
        props.onSelectionChange(e.target.value);
    };

    return Object.keys(topics).length === 0 ? (
        <h1>fetching data...</h1>
    ) : (
        <div className="topic-dropdown">
            <select onChange={handleChange}>
                {/* first option is placeholder */}
                <option value="">Bitte ein Thema auswählen</option>
                {topics.map((topic) => {
                    return (
                        <option key={topic.code} value={topic.code}>
                            {topic.name}
                        </option>
                    );
                })}
            </select>
        </div>
    );
}
