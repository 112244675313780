import React, { createContext, useContext, useState } from "react";

const ThemeContext = createContext(undefined);

// ThemeProvider to switch between dark and light themes
export const ThemeProvider = ({ children }) => {
    const [theme, setTheme] = useState("light");
    const toggleTheme = () =>
        theme === "light" ? setTheme("dark") : setTheme("light");

    return (
        <ThemeContext.Provider value={{ theme, toggleTheme }}>
            {children}
        </ThemeContext.Provider>
    );
};

export const useTheme = () => {
    const context = useContext(ThemeContext);
    if (context === undefined) {
        throw new Error("useTheme must be used within a ThemeProvider");
    }
    return context;
};
