import React, { createContext, useContext, useState, useEffect } from "react";

// create a context for the auth state
const AuthContext = createContext(undefined);

// AuthProvider to manage the auth state
export const AuthProvider = ({ children }) => {
    const [token, setToken] = useState("");
    const [isAuthenticated, setIsAuthenticated] = useState(false);

    const login = async (newToken) => {
        await fetch(`/backend/validate-token`, {
            method: "GET",
            headers: {
                Authorization: `Bearer ${newToken}`,
                "Content-Type": "application/json",
            },
            mode: "cors",
        })
        .then((response) => {
            if (response.status === 200) {
                setToken(newToken);
                setIsAuthenticated(true);
                localStorage.setItem("authToken", newToken);
            } else {
                setIsAuthenticated(false);
            }
        })
        .catch((error) => {
            console.error("Error validating token:", error);
            setIsAuthenticated(false);
        });
    };

    const logout = () => {
        setToken("");
        setIsAuthenticated(false);
        localStorage.removeItem("authToken");
    };

    useEffect(() => {
        const storedToken = localStorage.getItem("authToken");
        if (storedToken) {
            login(storedToken);
        }
    }, []);

    return (
        <AuthContext.Provider value={{ token, isAuthenticated, login, logout }}>
            {children}
        </AuthContext.Provider>
    );
};

// create a custom hook to use the auth context
export const useAuth = () => {
    const context = useContext(AuthContext);
    if (context === undefined) {
        throw new Error("useAuth must be used within an AuthProvider");
    }
    return context;
};
