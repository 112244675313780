import React, { useState, forwardRef, useImperativeHandle } from "react";
import { FaStar, FaStarHalfAlt, FaRegStar } from "react-icons/fa";

const Stars = forwardRef((props, ref) => {
    const [rating, setRating] = useState(0);

    const handleClick = (ratingValue) => {
        setRating(ratingValue);
        props.starChange(ratingValue);
    };

    const getStarIcon = (starIndex) => {
        if (rating >= starIndex) {
            return <FaStar />; // Full star
        } else if (rating >= starIndex - 0.5) {
            return <FaStarHalfAlt />; // Half star
        } else {
            return <FaRegStar />; // Empty star
        }
    };

    useImperativeHandle(ref, () => ({
        resetRating() {
            setRating(0);
        }
    }));

    return (
        <div className="star-container">
            <div className="stars">
                {[1, 2, 3, 4, 5].map((star, index) => (
                    <span
                        key={index}
                        onClick={(e) => {
                            const clickX = e.nativeEvent.offsetX;
                            const starWidth = e.currentTarget.offsetWidth;
                            const ratingValue =
                                clickX < starWidth / 2 ? star - 0.5 : star;
                            handleClick(ratingValue);
                        }}
                    >
                        {getStarIcon(star)}
                    </span>
                ))}
            </div>
            <label>{props.label}</label>
        </div>
    );
});

export default Stars;
