import React, { useEffect, useState, useRef } from "react";
import Stars from "./Stars.js";
import { useAuth } from "../Contexts/AuthContext";

const StarRating = (props) => {
    const { token } = useAuth();
    const [comment, setComment] = useState("");
    const [description, setDescription] = useState({});
    const [coverage, setCoverage] = useState(0);
    const [relevance, setRelevance] = useState(0);
    const [readability, setReadability] = useState(0);
    // variable to trigger useEffect when needed
    const [triggerEffect, setTriggerEffect] = useState(Date.now());

    const coverageRef = useRef();
    const relevanceRef = useRef();
    const readabilityRef = useRef();

    const handleCoverageStarChange = (value) => setCoverage(value);
    const handleRelevanceStarChange = (value) => setRelevance(value);
    const handleReadabilityStarChange = (value) => setReadability(value);

    const handleCommentChange = (e) => {
        e.preventDefault();
        setComment(e.target.value);
    };

    const getDescription = async () => {
        // fetch possible descriptions for the selected topic
        await fetch(`/backend/testcase/${props.topicCode}`, {
            method: "GET",
            headers: {
                Authorization: `Bearer ${token}`,
                "Content-Type": "application/json",
            },
            mode: "cors",
        })
            .then((response) => {
                if (response.status === 200) {
                    return response.json();
                } else {
                    console.error("Error fetching Descriptions:", response);
                    throw new Error("Error fetching descriptions");
                }
            })
            .then((data) => {
                setDescription(data);
            })
            .catch((error) =>
                console.error("Error fetching description:", error)
            );
    };

    useEffect(() => {
        if (props.topicCode !== null && props.topicCode !== "") {
            getDescription();
        }
    }, [props.topicCode, triggerEffect]);

    const handleSubmit = (e) => {
        e.preventDefault();
        // construct the feedback object
        const feedback = {
            "feedback_id": description.feedback_id,
            "uuid": description.uuid,
            scores: {
                "coverage": Math.floor(coverage * 2),
                "relevance": Math.floor(relevance * 2),
                "readability": Math.floor(readability * 2),
            },
            "comment": comment,
        };
        //send it back to the API
        fetch(`/backend/assessment`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify(feedback),
        })
            .then((response) => response.json())
            .then((data) => {
                // reset the form
                setComment("");
                setCoverage(0);
                setRelevance(0);
                setReadability(0);
                coverageRef.current.resetRating();
                relevanceRef.current.resetRating();
                readabilityRef.current.resetRating();
                // manually trigger useEffect to fetch new options
                props.triggerNewPapers();
                setTriggerEffect(Date.now());
            })
            .catch((error) =>
                console.error("Error submitting feedback:", error)
            );
    };

    return props.topicCode ? (
        <form onSubmit={handleSubmit}>
            <div className="star-selector-container">
                <div className="description-container">
                    <h1>{description.title}</h1>
                    <div className="description">
                        <p>{description.descr}</p>
                    </div>
                </div>

                <div className="comment-star-container">
                    <div className="stars-and-button-container">
                        <div className="stars-container">
                            <Stars
                                ref={coverageRef}
                                starChange={handleCoverageStarChange}
                                label="Coverage"
                            />
                            <Stars
                                ref={relevanceRef}
                                starChange={handleRelevanceStarChange}
                                label="Relevance"
                            />
                            <Stars
                                ref={readabilityRef}
                                starChange={handleReadabilityStarChange}
                                label="Readability"
                            />
                        </div>
                        <div className="buttons-container">
                            <div className="button-container">
                                <button
                                    type="button"
                                    className="comment-submit"
                                    onClick={props.triggerNewPapers}
                                >
                                    Neue Paper
                                </button>
                            </div>
                            <div className="button-container">
                                <button
                                    type="submit"
                                    className="comment-submit"
                                    disabled={
                                        coverage === 0 ||
                                        relevance === 0 ||
                                        readability === 0
                                    }
                                >
                                    Absenden
                                </button>
                            </div>
                        </div>
                    </div>
                    <div className="comment-container">
                        <label htmlFor="comment">Kommentar (optional)</label>
                        <textarea
                            name="comment"
                            type="input"
                            value={comment}
                            onChange={handleCommentChange}
                        />
                    </div>
                </div>
            </div>
        </form>
    ) : null;
};

export default StarRating;
