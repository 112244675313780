import React, { useEffect, useState } from "react";
import "./App.scss";
import Dropdown from "./Components/Dropdown";
import PapersCarousel from "./Components/PapersCarousel";
// import FeedbackSelector from "./Components/FeedbackSelector";
import StarSelector from "./Components/StarSelector";
import { useAuth } from "./Contexts/AuthContext";
import { useTheme } from "./Contexts/ThemeContext";
import { MdLightMode, MdDarkMode, MdLogout } from "react-icons/md";

function App() {
    const { theme, toggleTheme } = useTheme();
    const { isAuthenticated, login, logout } = useAuth();
    const [topicSelection, setTopicSelection] = useState(null);
    const [tokenInput, setTokenInput] = useState("");
    const [triggerEffect, setTriggerEffect] = useState(Date.now());

    const handleTokenInputChange = (e) => setTokenInput(e.target.value);
    const handleSelectionChange = (value) => setTopicSelection(value);
    const handleLogin = (e) => {
        e.preventDefault();
        login(tokenInput);
    };

    const triggerNewPapers = () => {
        setTriggerEffect(Date.now());
    }

    if (!isAuthenticated) {
        return (
            <div className={`App ${theme === "light" ? "light" : "dark"} lockscreen`}>
                <button id="theme-btn" onClick={toggleTheme}>
                    {theme === "light" ? <MdDarkMode /> : <MdLightMode />}
                </button>
                <div className="banner-container">
                    <h1>QUANTUM COSMOS</h1>
                </div>
                <form className="login-form">
                    <h3>Bitte Token eingeben</h3>
                    <input
                        type="text"
                        placeholder="Token"
                        value={tokenInput}
                        onChange={handleTokenInputChange}
                    />
                    <button onClick={handleLogin}>Login</button>
                </form>
            </div>
        );
    }


    return (
        <div className={`App ${theme === "light" ? "light" : "dark"}`}>
            {/* theme button in the top right */}
            <button id="theme-btn" onClick={toggleTheme}>
                {theme === "light" ? <MdDarkMode /> : <MdLightMode />}
            </button>
            <button id="logout-btn" onClick={logout}>
                <MdLogout />
            </button>

            <Dropdown onSelectionChange={handleSelectionChange} />
            <PapersCarousel topicCode={topicSelection} triggerEffect={triggerEffect} />
            <StarSelector topicCode={topicSelection}  triggerNewPapers={triggerNewPapers} />
        </div>
    );
}

export default App;
