import React, { useEffect, useState } from "react";

export default function PapersCarousel(props) {
    const [papers, setPapers] = useState([]);

    useEffect(() => {
        // fetch papers for the selected topic
        // TODO: better error checking and handling?
        if (props.topicCode) {
            fetch(`/backend/topic_papers/${props.topicCode}/10`)
                .then((response) => response.json())
                .then((data) => {
                    setPapers(data);
                })
                .catch((error) => console.error("Error fetching papers:", error));
        }
    }, [props.topicCode, props.triggerEffect]);

    const openPaper = (e) => {
        e.preventDefault();
        e.target.classList.toggle("modal");
    }

    const papers_carousel = papers.map((paper) => {
        return (
            <div key={paper.title} className="paper" onClick={openPaper}>
                <h3>{paper.title}</h3>
                <h5>{paper.authors.split(";").join("; ")}</h5>
                <p>{paper.description}</p>
            </div>
        );
    });


    return props.topicCode ? (
        <div className="papers-carousel">
            {papers_carousel}
        </div>
    ) : (
        null
    );
}
